import { ComponentProps, FC } from 'react';

import { Icon } from './Icon';

export const IconCircleCheck: FC<ComponentProps<typeof Icon>> = (props) => (
  <Icon {...props} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM10.9422 15.4054L16.8476 8.96314L15.963 8.15228L10.481 14.1326L7.9999 11.6515L7.15137 12.5L10.0756 15.4243C10.1914 15.54 10.3493 15.6034 10.5129 15.5999C10.6765 15.5963 10.8316 15.5261 10.9422 15.4054Z"
    />
  </Icon>
);
